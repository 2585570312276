<template>
  <div>
    <v-toolbar>
      <v-toolbar-title>{{ $t('POSTS.LIST_HEADER') }}</v-toolbar-title>
      <v-spacer></v-spacer>

      <v-btn dark color="indigo" @click="create">
        <v-icon left>mdi-plus</v-icon>
        {{ $t('ITEM.NEW') }} {{ $t('POSTS.ITEM') }}
      </v-btn>
    </v-toolbar>

    <v-sheet class="mt-5 pa-5" tile>
      <posts-list :posts="posts" @edit="edit" @refresh="refreshData" />
    </v-sheet>
  </div>
</template>

<script>
import { PostsService } from '@/services/posts.service';

export default {
  name: 'PostsPage',

  components: {
    PostsList: () => import('@/modules/posts/components/PostsList.vue'),
  },

  data: () => ({
    dialog: false,
    posts: undefined,
  }),

  mounted() {
    this.refreshData();
  },

  methods: {
    edit(item) {
      this.$router.push({
        name: 'posts.edit',
        params: { id: item.id },
      });
    },

    create() {
      this.$router.push({
        name: 'posts.create',
      });
    },

    refreshData({ params } = {}) {
      PostsService.getList(params)
        .then(({ data }) => {
          this.posts = data;
        })
        .catch((error) => {
          this.error = error.response;
        });
    },
  },
};
</script>
