import { BaseService } from './base.service';
import { ErrorWrapper, ResponseWrapper } from './util';

export class PostsService extends BaseService {
  static get entity() {
    return 'posts';
  }

  static async getAuthorCompanies() {
    try {
      const response = await this.request({ auth: true, base_url: true }).get(
        `${this.entity}/author-companies`
      );
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
}
